import logo from "./logo.svg";
import EmbedForm from "./Components/EmbedForm";
import DonationFlag from "./Components/DonationFlag";
import { useEffect, useState } from "react";
import QuickDonate from "./Components/QuickDonate";
import FundraisingBar from "./Components/FundraisingBar";
import DonorList from "./Components/DonorList";
import TicketSelectionForm from "./Components/TicketSelectionForm";
import AddToCart from "./Components/AddToCart";

function App() {
  const [entity, setEntity] = useState("");
  const [urlParams, setUrlParams] = useState({});

  useEffect(() => {
    // grab url entity param
    const urlParams = new URLSearchParams(window.location.search);
    // console.log(urlParams)
    const entity = urlParams.get("entity");
    // console.log('entity', entity)
    setEntity(entity);
    setUrlParams(urlParams);
  }, []);

  return (
    <>
      {entity === "EVENT" && <TicketSelectionForm />}
      {entity === "EMBED_FORM" && <EmbedForm />}
      {entity === "DONATION_FLAG" && <DonationFlag />}
      {entity === "QUICK_DONATE" && <QuickDonate />}
      {entity === "FUNDRAISING_BAR" && <FundraisingBar />}
      {entity === "DONOR_LIST" && <DonorList />}
      {entity === "ADD_TO_CART" && <AddToCart />}
    </>
  );
}

export default App;
