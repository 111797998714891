/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function CustomAmount({
  amount,
  setAmount,
  maxTransactionAmount,
  elementID,
}) {
  const handleOnChange = (event) => {
    let value = event.target.value;

    //only allow numbers and periods
    value = value.replace(/[^0-9.].*/g, "");

    //allows only 1 period
    let decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount == 2) {
      let index = value.lastIndexOf(".");
      value = value.substr(0, index);
    }

    // limit to two decimal places
    value = value.replace(/(\.\d{2})\d{1,}/g, "$1");

    if (value === ".") {
      value = "0.";
    }

    // Checking if current total will surpass max transaction amount
    if (+value > maxTransactionAmount) {
      value = value.replace(/.$/g, "");
    }

    setAmount(value);
  };

  return (
    <div
      className="mt-2 flex items-center rounded-md shadow-sm"
      id={`${elementID}-custom-amount`}
    >
      <span
        className="inline-flex h-10 items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-5 py-3 text-xl text-gray-500"
        id={`${elementID}-custom-amount-currency`}
      >
        USD
      </span>
      <div
        className="relative w-full"
        id={`${elementID}-custom-amount-input-container`}
      >
        <input
          type="tel"
          name="custom-amount"
          id={`${elementID}-custom-amount-input`}
          className="peer block h-10 w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-2 pb-2 pt-4 text-sm text-gray-900 placeholder-transparent focus:outline-none sm:leading-6"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => handleOnChange(e)}
        />
        <label
          for={`${elementID}-custom-amount-input`}
          id={`${elementID}-custom-amount-input-label`}
          className="absolute left-2 top-[0px] pt-[1.5px] text-2xs text-gray-600 transition-all peer-placeholder-shown:top-[5px] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-[3.25px] peer-focus:text-2xs peer-focus:text-gray-600"
        >
          Enter Amount
        </label>
      </div>
    </div>
  );
}
