import React, { useEffect, useState } from "react";
import DonationFrequencies from "./Util/DonationFrequencies";
import CustomAmount from "./Util/CustomAmount";
import FundsDropdown from "./Util/FundsDropdown";
import AmountButtons from "./Util/AmountButtons";
import CustomInputsPage from "./Util/CustomInputs";
import { QuickDonateShimmer } from "./Util/Shimmer";

const freqMap = {
  "": "",
  ONE_TIME: "One-Time",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ANNUALLY: "Yearly",
};

function QuickDonate() {
  const [hostedPage, setHostedPage] = useState(null);
  const [elementID, setElementID] = useState("");
  const [configData, setConfigData] = useState({});
  const [cardMin, setCardMin] = useState(1.0);    
  const [maxTransactionAmount, setMaxTransactionAmount] = useState(1000);
  const [customInputsValues, setCustomInputsValues] = useState([]);
  const [primaryColor, setPrimaryColor] = useState("#69995E");
  const [secondaryColor, setSecondaryColor] = useState("#496b42");
  const [funds, setFunds] = useState([
    "General Fund",
    "Zakat Fund",
    "Sadaqah Fund",
  ]);
  const [frequencies, setFrequencies] = useState([
    "ONE_TIME",
    "DAILY",
    "WEEKLY",
    "MONTHLY",
    "ANNUALLY",
  ]);
  const [oneTimeAmounts, setOneTimeAmounts] = useState([
    "10",
    "25",
    "50",
    "100",
  ]);
  const [dailyAmounts, setDailyAmounts] = useState(["10", "25", "50", "100"]);
  const [weeklyAmounts, setWeeklyAmounts] = useState(["10", "25", "50", "100"]);
  const [monthlyAmounts, setMonthlyAmounts] = useState([
    "10",
    "25",
    "50",
    "100",
  ]);
  const [annuallyAmounts, setAnnuallyAmounts] = useState([
    "10",
    "25",
    "50",
    "100",
  ]);
  const [defaultOneTimeAmount, setDefaultOneTimeAmount] = useState("10");
  const [defaultDailyAmount, setDefaultDailyAmount] = useState("25");
  const [defaultWeeklyAmount, setDefaultWeeklyAmount] = useState("50");
  const [defaultMonthlyAmount, setDefaultMonthlyAmount] = useState("100");
  const [defaultAnnuallyAmount, setDefaultAnnuallyAmount] = useState("10");

  const [page, setPage] = useState("START_PAGE");
  const [defaultAmounts, setDefaultAmounts] = useState(oneTimeAmounts);

  const [frequency, setFrequency] = useState("");
  const [amount, setAmount] = useState("0");
  const [selectedFund, setSelectedFund] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorDisplayTime, setErrorDisplayTime] = useState(0);
  const [errorMessageInterval, setErrorMessageInterval] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addToCart, setAddToCart] = useState(false);

  const [domID, setDomID] = useState("");

  const listener = (event) => {
    if (
      !(
        event.data.entity == "CHARITYSTACK" &&
        event.data.action == "RESET_ELEMENT"
      )
    )
      return;

    setPage("START_PAGE");
    setFrequency("");
    setAmount("");
    setSelectedFund("");

    fetchElementsConfig().catch((err) => console.log("ERROR", err));
  };

  // create an async useEffect to fetch elements config
  const fetchElementsConfig = async () => {
    // Grab elements config using elementID in query params
    const urlParams = new URLSearchParams(window.location.search);
    // if elementID is not in query params, return a hardcoded one
    let elementID = urlParams.get("elementID");

    if (elementID == null) {
      elementID =
        process.env.REACT_APP_ENVIRONMENT == "development"
          ? "abde7b6e-a8fe-49ce-8979-c09cb3203359"
          : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    }
    setElementID(elementID);

    const response = await fetch(
      `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`,
    );
    const data = await response.json();

    // preprocess data
    data.customInputs.forEach((input) => {
      input.answers = [""];
    });
    // convert amounts to strings
    // data.oneTimeAmounts = data.oneTimeAmounts.map(amount => amount.toString())
    // data.dailyAmounts = data.dailyAmounts.map(amount => amount.toString())
    // data.weeklyAmounts = data.weeklyAmounts.map(amount => amount.toString())
    // data.monthlyAmounts = data.monthlyAmounts.map(amount => amount.toString())
    // data.annuallyAmounts = data.annuallyAmounts.map(amount => amount.toString())
    data.defaultOneTimeAmount = data.defaultOneTimeAmount.toString();
    data.defaultDailyAmount = data.defaultDailyAmount.toString();
    data.defaultWeeklyAmount = data.defaultWeeklyAmount.toString();
    data.defaultMonthlyAmount = data.defaultMonthlyAmount.toString();
    data.defaultAnnuallyAmount = data.defaultAnnuallyAmount.toString();
    setCardMin(data.cardMin);
    setMaxTransactionAmount(data.maxTransactionAmount);
    setCustomInputsValues(data.customInputs);
    setFunds(data.funds);
    if (data.funds.length == 1) {
      setSelectedFund(data.funds[0]);
    }
    setFrequencies(data.frequencies);
    setFrequency(data.defaultFrequency);
    // setOneTimeAmounts(data.oneTimeAmounts)
    // setDailyAmounts(data.dailyAmounts)
    // setWeeklyAmounts(data.weeklyAmounts)
    // setMonthlyAmounts(data.monthlyAmounts)
    // setAnnuallyAmounts(data.annuallyAmounts)
    setDefaultOneTimeAmount(data.defaultOneTimeAmount);
    setDefaultDailyAmount(data.defaultDailyAmount);
    setDefaultWeeklyAmount(data.defaultWeeklyAmount);
    setDefaultMonthlyAmount(data.defaultMonthlyAmount);
    setDefaultAnnuallyAmount(data.defaultAnnuallyAmount);
    setPrimaryColor(data.lightColor);
    setSecondaryColor(data.darkColor);
    setLoading(false);
    setConfigData(data);
    setAddToCart(data.showAddToCart === undefined ? null : data.showAddToCart);
  };

  useEffect(() => {
    if (frequency == "ONE_TIME") {
      setAmount(defaultOneTimeAmount == "0" ? "" : defaultOneTimeAmount);
    }
    if (frequency == "DAILY") {
      setAmount(defaultDailyAmount == "0" ? "" : defaultDailyAmount);
    }
    if (frequency == "WEEKLY") {
      setAmount(defaultWeeklyAmount == "0" ? "" : defaultWeeklyAmount);
    }
    if (frequency == "MONTHLY") {
      setAmount(defaultMonthlyAmount == "0" ? "" : defaultMonthlyAmount);
    }
    if (frequency == "ANNUALLY") {
      setAmount(defaultAnnuallyAmount == "0" ? "" : defaultAnnuallyAmount);
    }
  }, [frequency]);

  useEffect(() => {
    if (loading === false && hostedPage !== null) {
      // send message that element has finished loading
      window.parent.postMessage(
        {
          action: "FINISHED_LOADING",
          entity: "CHARITYSTACK",
          sender: "QUICK_DONATE",
          receiver: "HOSTED_PAGE",
        },
        "*",
      );
    }
  }, [loading]);

  useEffect(() => {
    resizeIframe();
  }, [loading, page]);

  useEffect(() => {
    fetchElementsConfig().catch((err) => console.log("ERROR", err));
    window.addEventListener("message", listener);
    // grab hostedPage boolean
    const urlParams = new URLSearchParams(window.location.search);
    const hostedPage = urlParams.get("page");
    // page can be either null, HOSTED_PAGE_FULLSCREEN, or HOSTED_PAGE_POPUP
    setHostedPage(hostedPage);
    setDomID(urlParams.get("domID"));
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    let timer;
    if (errorMessage != "") {
      timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);

  useEffect(() => {
    if (errorDisplayTime >= 3) {
      clearInterval(errorMessageInterval);
      setErrorDisplayTime(0);
      setErrorMessage("");
    }
  }, [errorDisplayTime]);

  const resizeIframe = () => {
    const height = document.body.scrollHeight;
    const width = document.body.scrollWidth;

    // Send the content height to the parent window
    window.parent.postMessage(
      {
        action: "RESIZE",
        entity: "CHARITYSTACK",
        sender: "QUICK_DONATE",
        receiver: "HOSTED_SCRIPT",
        data: JSON.stringify({
          height: height,
          width: width,
          elementID: elementID,
          domID: domID,
        }),
      },
      "*",
    );
  };

  const hasErrors = () => {
    if (frequency == "") {
      setErrorMessage("Please select a frequency");
      return true;
    }
    if (amount == "") {
      setErrorMessage("Please select an amount");
      return true;
    }
    if (+amount < cardMin) {
      setErrorMessage("Please select an amount that is at least $" + cardMin);
      return true;
    }
    if (selectedFund == "") {
      setErrorMessage("Please select a fund");
      return true;
    }
    return false;
  };

  
  const handleButtonClick = () => {
    if (hasErrors()) {
      return;
    }
    if (page === "START_PAGE" && customInputsValues.length > 0) {
      setPage("CUSTOM_INPUTS_PAGE");
    } else {
      handleDonate();
    }
  };

  const handleDonate = () => {
    const data = configData;

    // updated configData to include user inputs
    data.frequency = frequency;
    data.amount = amount;
    data.fund = selectedFund;
    data.customInputsValues = customInputsValues;

    // convert amounts to float. Can remove later since not needed on donation form side
    // data.oneTimeAmounts = data.oneTimeAmounts.map(amount => parseFloat(amount))
    // data.dailyAmounts = data.dailyAmounts.map(amount => parseFloat(amount))
    // data.weeklyAmounts = data.weeklyAmounts.map(amount => parseFloat(amount))
    // data.monthlyAmounts = data.monthlyAmounts.map(amount => parseFloat(amount))
    // data.annuallyAmounts = data.annuallyAmounts.map(amount => parseFloat(amount))

    // pass in oneTimeAmounts, dailyAmounts, etc. to donation form just so
    // it doesn't complain. remove later when checkoutform doesn't read it
    data.oneTimeAmounts = oneTimeAmounts;
    data.dailyAmounts = dailyAmounts;
    data.weeklyAmounts = weeklyAmounts;
    data.monthlyAmounts = monthlyAmounts;
    data.annuallyAmounts = annuallyAmounts;

    data.defaultOneTimeAmount = parseFloat(data.defaultOneTimeAmount);
    data.defaultDailyAmount = parseFloat(data.defaultDailyAmount);
    data.defaultWeeklyAmount = parseFloat(data.defaultWeeklyAmount);
    data.defaultMonthlyAmount = parseFloat(data.defaultMonthlyAmount);
    data.defaultAnnuallyAmount = parseFloat(data.defaultAnnuallyAmount);

    data.hostedPage = hostedPage;

    if (addToCart === true) {
      window.top.postMessage(
        {
          action: "UPDATE_CART",
          entity: "CHARITYSTACK",
          sender: "STANDARD_FORM",
          receiver: "HOSTED_SCRIPT",
          data: JSON.stringify(data),
          id: data.sourceWebsite,
        },
        "*",
      );
    } else {
      window.top.postMessage(
        {
          action: "DONATE",
          entity: "CHARITYSTACK",
          sender: "QUICK_DONATE",
          receiver: "HOSTED_SCRIPT",
          hostedPage: hostedPage,
          donationData: JSON.stringify(data),
        },
        "*",
      );
    }
    // }, window.location.ancestorOrigins[0]);
  };

  const handleOnChange = (event) => {
    let value = event.target.value;

    //only allow numbers and periods
    value = value.replace(/[^0-9.].*/g, "");

    //allows only 1 period
    let decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount == 2) {
      let index = value.lastIndexOf(".");
      value = value.substr(0, index);
    }

    // limit to two decimal places
    value = value.replace(/(\.\d{2})\d{1,}/g, "$1");

    if (value === ".") {
      value = "0.";
    }

    // Checking if current total will surpass max transaction amount
    if (+value > maxTransactionAmount) {
      value = value.replace(/.$/g, "");
    }

    setAmount(value);
  };

  if (loading) {
    return (
      <>
        <div className="container mx-auto h-full max-w-sm items-center">
          <div className="grid grid-cols-1 rounded-md border border-gray-200 bg-white px-3 py-4">
            <QuickDonateShimmer />
          </div>
        </div>
      </>
    );
  }
  if (!loading) {
    if (configData.active === false) {
      return (
        <>
          <div className="container mx-auto h-full max-w-sm items-center">
            <div className="h-18 grid grid-cols-1 rounded-md border border-gray-200 bg-white px-3 py-4 text-center text-gray-500">
              Deactivated Form: {configData.formName}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div data-name={configData.elementName}>
          {
            <div
              className={`container mx-auto h-full items-center ${
                hostedPage !== null
                  ? funds.length > 1
                    ? "max-w-2xl"
                    : "max-w-lg"
                  : funds.length > 1
                  ? "max-w-xl"
                  : "max-w-sm"
              }`}
            >
              <div
                className={`grid grid-cols-1 bg-white ${
                  hostedPage !== null
                    ? ""
                    : "rounded-md border border-gray-200 px-3 py-4"
                }`}
              >
                <div className="">
                  {/* <CustomAmount amount={amount} setAmount={setAmount} maxTransactionAmount={maxTransactionAmount} /> */}
                  <div className="flex flex-col items-center rounded-md shadow-sm sm:flex-row">
                    <div
                      className={`flex w-full pb-2 sm:pb-0 sm:pr-2 ${
                        funds.length > 1 ? "sm:w-2/5" : "sm:w-2/3"
                      }`}
                    >
                      <span className="inline-flex h-10 items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-5 py-3 text-xl text-gray-500">
                        USD
                      </span>
                      <div className="relative w-full">
                        <input
                          type="tel"
                          name="custom-amount"
                          id="custom-amount"
                          className="peer block h-10 w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-2 pb-2 pt-4 text-sm text-gray-900 placeholder-transparent focus:outline-none sm:leading-6"
                          placeholder="Enter Amount"
                          value={amount}
                          onChange={(e) => handleOnChange(e)}
                        />
                        <label
                          for="custom-amount"
                          className="absolute left-2 top-[0px] pt-[2px] text-2xs text-gray-600 transition-all peer-placeholder-shown:top-[5px] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-[3.25px] peer-focus:text-2xs peer-focus:text-gray-600"
                        >
                          Enter Amount
                        </label>
                      </div>
                    </div>
                    {funds.length > 1 && (
                      <div className="w-full pb-2 sm:w-2/5 sm:pb-0 sm:pr-2">
                        <FundsDropdown
                          funds={funds}
                          selectedFund={selectedFund}
                          setSelectedFund={setSelectedFund}
                        />
                      </div>
                    )}
                    <button
                      type="button"
                      className={
                        "h-10 w-full rounded-md px-1 py-1 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:py-0" +
                        (errorMessage !== "" ? " text-sm" : " text-sm") +
                        (funds.length > 1 ? " sm:w-1/5" : " sm:w-1/3")
                      }
                      onClick={handleButtonClick}
                      style={{ backgroundColor: primaryColor, color: "white" }}
                    >
                      {errorMessage !== ""
                        ? errorMessage
                        : `${addToCart === true ? "Add" : "Donate"}` +
                          (frequency === "ONE_TIME"
                            ? ""
                            : ` ${freqMap[frequency]}`)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    }
  }
}

export default QuickDonate;
