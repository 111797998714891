import React, { useEffect, useState } from "react";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const AddToCart = () => {
  const [data, setData] = useState({});
  const [cart, setCart] = useState({
    data: null,
    cartItems: [],
  });
  const [sourceWebsite, setSourceWebsite] = useState("");
  const [elementID, setElementID] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [color, setColor] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(
    "Your donation has been added to your cart.",
  );

  useEffect(() => {
    fetchElementsConfig();
    // add an eventlistener to update windowWidth state
    window.addEventListener("resize", (event) => {
      if (window.innerWidth == windowWidth) return; // dont do anything if windowWidth is the same
      if (window.innerWidth !== 48 && window.innerWidth !== 64) return; // dont do anything if windowWidth isn't 48 or 64
      //console.log("hello in in resizde of add to cart");
      setWindowWidth(window.innerWidth);
    });
    // add an eventlistener to update cart state
    window.addEventListener("message", updateCart);
    // add an eventlistener to update notification message
    window.addEventListener("message", updateMessage);

    // set the elementID
    const urlParams = new URLSearchParams(window.location.search);
    let elementID = urlParams.get("elementID");
    setElementID(elementID);
    let sourceWebsite = urlParams.get("sourceWebsite");
    setSourceWebsite(sourceWebsite);
    setColor(urlParams.get("color"));
    // send message to grab current cart
    window.top.postMessage(
      {
        action: "GET_CART",
        entity: "CHARITYSTACK",
        sender: "ADD_TO_CART",
        receiver: "HOSTED_SCRIPT",
        id: sourceWebsite,
      },
      "*",
    );
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
      window.removeEventListener("message", updateCart);
      window.removeEventListener("message", updateMessage);
    };
  }, []);

  const updateMessage = (event) => {
    if (
      !(
        event.data.entity == "CHARITYSTACK" &&
        event.data.action == "UPDATE_CART_NOTIFICATION"
      )
    )
      return;
    console.log("event data here", event.data);
    setNotificationMessage(event.data.message);
  };

  // update cart to be the new event
  const updateCart = (event) => {
    if (
      !(
        event.data.entity == "CHARITYSTACK" &&
        event.data.action == "UPDATED_CART"
      )
    )
      return;
    //console.log("hello", event.data.data);
    const newCart = JSON.parse(event.data.data);
    if (newCart == null) {
      setCart({
        data: null,
        cartItems: [],
      });
    } else {
      setCart(newCart);
    }
  };

  const fetchElementsConfig = async () => {
    // Grab elements config using elementID in query params
    const urlParams = new URLSearchParams(window.location.search);
    // if elementID is not in query params, return a hardcoded one
    let elementID = urlParams.get("elementID");

    // if (elementID == null) {
    //   elementID =
    //     process.env.REACT_APP_ENVIRONMENT == "development"
    //       ? "d19c007c-6dae-48e9-9809-54905bc6ddaa"
    //       : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    // }

    const response = await fetch(
      `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`,
    );
    const data = await response.json();
    setData(data);
    //console.log("data", data);
    setSourceWebsite(data.sourceWebsite);
    //console.log("windowWidth", windowWidth);
  };

  const handleDonate = () => {
    // updated configData to include user inputs
    // data.frequency = frequency;
    // data.amount = amount;
    // data.fund = selectedFund;
    // data.customInputsValues = customInputsValues;
   // console.log("Naumaan: ", cart.cartItems)
    const cartQuantity = cart.cartItems.reduce((a, b) => a + b.quantity, 0);
    if (cartQuantity == 0) return;

    window.top.postMessage(
      {
        action: "DONATE",
        entity: "CHARITYSTACK",
        sender: "ADD_TO_CART",
        receiver: "HOSTED_SCRIPT",
        hostedPage: null,
        donationData: JSON.stringify(cart),
      },
      "*",
    );
    // }, window.location.ancestorOrigins[0]);
  };

  if (color != null) {
    return (
      <button
        className={`fixed cursor-pointer items-center justify-center pt-[2px] ${
          data.nonprofitName === "ICNA Relief USA" ||
          data.nonprofitName === "ICNA Relief USA Dev" ||
          data.nonprofitName === "Aghosh AlKhidmat USA"
            ? "right-0 top-0 "
            : "bottom-0 right-0"
        }`}
        onClick={() => handleDonate()}
        id="add-to-cart-button"
        data-tooltip-id="add-to-cart-tooltip"
        data-tooltip-offset={10}
        data-tooltip-content={notificationMessage}
      >
        {/* Orange Circle (Relative) */}
        <div
          className={`relative rounded-full border-2 border-gray-300 bg-orange-500 ${
            // windowWidth < 64 ? "h-12 w-12" : "h-16 w-16"
            windowWidth < 64 ? "h-16 w-16" : "h-16 w-16"
          }`}
          style={{ backgroundColor: color }}
        >
          {/* Red Circle (Top Right) */}
          <div
            className={`absolute right-0 top-0 -mt-1 ml-1 flex items-center justify-center rounded-full bg-red-500 text-xs text-white ${
              // windowWidth < 64 ? "h-4 w-4" : "h-6 w-6"
              windowWidth < 64 ? "h-6 w-6" : "h-6 w-6"
            }`}
          >
            {/* Add up all cartItems by summing quantities */}
            {cart.cartItems.reduce((a, b) => a + b.quantity, 0)}
          </div>
          {/* Shopping Cart Icon (Center) */}
          <div className="absolute inset-0 flex items-center justify-center">
            <ShoppingBagIcon
              className={`text-white ${
                // windowWidth < 64 ? "h-6 w-6" : "h-8 w-8"
                windowWidth < 64 ? "h-8 w-8" : "h-8 w-8"
              }`}
              style={{ strokeWidth: 1.0 }}
            />
          </div>
        </div>

        {/* Tooltip */}
        <Tooltip
          id="add-to-cart-tooltip"
          place="top-end"
          anchorSelect="#add-to-cart-button"
          style={{
            backgroundColor: "rgb(55 65 81)",
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.25rem",
            fontSize: "4.5vw",
            zIndex: 10,
          }}
          isOpen={true}
          border={"1px solid #d1d5db"}
        />
      </button>
    );
  }
};

export default AddToCart;
