import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect } from "react";

function AmountButtons({
  primaryColor,
  amount,
  setAmount,
  defaultAmounts,
  resizeIframe,
  elementID,
}) {
  const defaultAmountsLenToWidth = {
    1: "w-full",
    2: "w-1/2",
    3: "w-1/3",
    4: "w-1/4",
    5: "w-1/5",
    6: "w-1/6",
    7: "w-1/7",
    8: "w-1/8",
  };

  useEffect(() => {
    resizeIframe();
  }, [defaultAmounts]);

  return (
    <>
      <div
        className="inline-flex w-full justify-between space-x-1 text-xl"
        id={`${elementID}-amount-buttons`}
      >
        {defaultAmounts.map((defaultAmount, i) => {
          if (i < 4) {
            return (
              <button
                type="button"
                className={
                  "h-10 w-1/4 rounded-md border bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={(e) => setAmount(e.target.value)}
                style={
                  amount === defaultAmount
                    ? { backgroundColor: primaryColor, color: "white" }
                    : { backgroundColor: "white", color: "gray" }
                }
                value={defaultAmount}
                id={`${elementID}-amount-button-${i}`}
              >
                ${defaultAmount}
              </button>
            );
          }
        })}
      </div>
      {defaultAmounts.length > 4 && (
        <div className="inline-flex w-full justify-between space-x-1 pt-2 text-xl">
          {defaultAmounts.map((defaultAmount, i) => {
            if (i >= 4) {
              return (
                <button
                  type="button"
                  className={
                    "h-10 w-1/4 rounded-md border bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  }
                  onClick={(e) => setAmount(e.target.value)}
                  style={
                    amount === defaultAmount
                      ? { backgroundColor: primaryColor, color: "white" }
                      : { backgroundColor: "white", color: "gray" }
                  }
                  value={defaultAmount}
                  id={`${elementID}-amount-button-${i}`}
                >
                  ${defaultAmount}
                </button>
              );
            }
          })}
        </div>
      )}

      {/* <button
                type="button"
                className="w-1/4 h-10 border rounded-md bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => setAmount(e.target.value)}
                style={amount === defaultAmounts[0] ? { backgroundColor: primaryColor, color: 'white' } : { backgroundColor: 'white', color: 'gray' }}
                value={defaultAmounts[0]}
            >
                ${defaultAmounts[0]}
            </button>
            <button
                type="button"
                className="w-1/4 h-10 border rounded-md bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => setAmount(e.target.value)}
                style={amount === defaultAmounts[1] ? { backgroundColor: primaryColor, color: 'white' } : { backgroundColor: 'white', color: 'gray' }}
                value={defaultAmounts[1]}
            >
                ${defaultAmounts[1]}
            </button>
            <button
                type="button"
                className="w-1/4 h-10 border rounded-md bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => setAmount(e.target.value)}
                style={amount === defaultAmounts[2] ? { backgroundColor: primaryColor, color: 'white' } : { backgroundColor: 'white', color: 'gray' }}
                value={defaultAmounts[2]}
            >
                ${defaultAmounts[2]}
            </button>
            <button
                type="button"
                className="w-1/4 h-10 border rounded-md bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => setAmount(e.target.value)}
                style={amount === defaultAmounts[3] ? { backgroundColor: primaryColor, color: 'white' } : { backgroundColor: 'white', color: 'gray' }}
                value={defaultAmounts[3]}
            >
                ${defaultAmounts[3]}
            </button> */}
    </>
  );
}

export default AmountButtons;
