import React from "react";
import { useEffect, useState } from "react";
import CustomShimmer from "./Util/Shimmer";
import CustomInputsPageEvents from "./Util/CustomInputsEvents";
import AttendeeInformationForm from "./Util/AttendeeInformationForm";

function TicketSelectionForm() {
  // write a function that takes a string timestamp formatted as 2023-08-02 04:37:29.897884
  // and converts it to a format like April 30th, 2023 at 7:52pm in the user's local timezone
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const timezoneOffsetInMinutes = date.getTimezoneOffset();

    // Apply the timezone offset to the date
    date.setMinutes(date.getMinutes() - timezoneOffsetInMinutes);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate;
  }
  const [hostedPage, setHostedPage] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorDisplayTime, setErrorDisplayTime] = useState(0);
  const [errorMessageInterval, setErrorMessageInterval] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configData, setConfigData] = useState({});

  const [active, setActive] = useState(false);
  const [elementID, setElementID] = useState("");
  const [elementName, setElementName] = useState("");
  const [tickets, setTickets] = useState([]);
  const [customInputsValues, setCustomInputsValues] = useState([]);
  const [customInputsAttendee, setCustomInputsAttendee] = useState([]);
  const [primaryColor, setPrimaryColor] = useState("#48B284");
  const [secondaryColor, setSecondaryColor] = useState("#496b42");

  const [page, setPage] = useState("START_PAGE");
  const [ticketTransactions, setTicketTransactions] = useState(new Map());
  const [totalTickets, setTotalTickets] = useState(0);
  const [total, setTotal] = useState(0);

  const [ticket2, setTicket2] = useState({});
  const [allAttendees, setAllAttendees] = useState([]); // this is the list of all attendees from all tickets
  const [map, setMapping] = useState({}); // this is the list of all attendees from all tickets
  const [amt, setAmt] = useState(0); // this is the list of all attendees from all tickets
  const [currentIndex, setCurrentIndex] = useState(0);

  const [domID, setDomID] = useState("");

  const extractIndex = (currPage) => {
    try {
      // Split the page string using '_' as a separator and get the second part
      const indexStr = currPage.split("_")[1];
      // Convert the extracted string to an integer
      const index = parseInt(indexStr, 10);
    } catch (error) {
      // Handle the case where the extraction or conversion fails
      console.error("Error extracting index:", error);
      setCurrentIndex(0);
    }
  };

  const calculateNewIndex = () => {
    if (currentIndex >= 1) {
      return currentIndex - 1;
    } else {
      return 0; // or any other value you want to return when currentIndex is not greater than 1
    }
  };

  const listener = (event) => {
    if (
      !(
        event.data.entity == "CHARITYSTACK" &&
        event.data.action == "RESET_ELEMENT"
      )
    )
      return;

    setPage("START_PAGE");
    setTicketTransactions(new Map());
    fetchElementsConfig().catch((err) => console.log("ERROR", err));
  };

  const goBackListener = (event) => {
    if (
      !(event.data.entity == "CHARITYSTACK" && event.data.action == "GO_BACK")
    )
      return;

    // this looks complicated b/c we can't read the states inside this function the normal way
    // tbh right now we can always set the state as START_PAGE b/c both if and else do that
    // but i am keeping this in case we need to actually change it to two or more values later
    setPage((currentPage) => {
      if (
        currentPage === "START_PAGE" ||
        currentPage === "CUSTOM_INPUTS_PAGE"
      ) {
        return "START_PAGE";
      } else if (currentPage == "ATTENDEE_0") {
        if (customInputsValues.length > 0) {
          setPage("CUSTOM_INPUTS_PAGE");
        } else {
          setPage("START_PAGE");
        }
      } else if (currentPage.startsWith("ATTENDEE")) {
        extractIndex(currentPage);
        calculateNewIndex();
        return `ATTENDEE_${currentIndex}`;
      }
      return "START_PAGE";
    });
  };

  useEffect(() => {
    // Send the content height to the parent window
    window.parent.postMessage(
      {
        action: "CHANGE_PAGE",
        entity: "CHARITYSTACK",
        sender: "EVENT",
        receiver: "HOSTED_PAGE",
        data: JSON.stringify({
          currentPage: page,
        }),
      },
      "*",
    );
    resizeIframe();
  }, [page]);

  useEffect(() => {
    if (loading === false && hostedPage !== null) {
      // send message that element has finished loading
      window.parent.postMessage(
        {
          action: "FINISHED_LOADING",
          entity: "CHARITYSTACK",
          sender: "EVENT",
          receiver: "HOSTED_PAGE",
        },
        "*",
      );
    }
  }, [loading]);

  useEffect(() => {
    resizeIframe();
  }, [loading, page]);

  useEffect(() => {
    window.addEventListener("message", listener);
    window.addEventListener("message", goBackListener);
    // grab hostedPage boolean
    const urlParams = new URLSearchParams(window.location.search);
    const hostedPage = urlParams.get("page");
    // page can be either null, HOSTED_PAGE_FULLSCREEN, or HOSTED_PAGE_POPUP
    setHostedPage(hostedPage);
    setDomID(urlParams.get("domID"));
    return () => {
      window.removeEventListener("message", listener);
      window.removeEventListener("message", goBackListener);
    };
  }, []);

  // create an async useEffect to fetch elements config
  const fetchElementsConfig = async () => {
    // Grab elements config using elementID in query params
    const urlParams = new URLSearchParams(window.location.search);
    // if elementID is not in query params, return a hardcoded one
    let elementID = urlParams.get("elementID");

    if (elementID == null) {
      elementID =
        process.env.REACT_APP_ENVIRONMENT == "development"
          ? "4cb2b64e-0f99-456f-9cb4-f704d7a63c66"
          : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    }
    setElementID(elementID);

    const response = await fetch(
      `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`,
    );
    const data = await response.json();

    if (data.elementType != "EVENT") {
      throw new Error("Wrong element type");
    }
    setConfigData(data);
    setActive(data.active);
    setElementName(data.elementName);

    data.customInputs.forEach((input) => {
      input.answers = [""];
    });

    data.tickets.forEach((input) => {
      input.soldNow = 0;
      input.soldNowPrice = 0;

      input.attendees = [];
    });
    setTickets(data.tickets);
    setPrimaryColor(data.lightColor);

    const customInputPurchaser = data.customInputs.filter(
      (question) => question.questionType === "PURCHASER",
    );

    const customInputAttendee = data.customInputs.filter(
      (question) => question.questionType === "ATTENDEE",
    );

    setCustomInputsValues(customInputPurchaser);
    setCustomInputsAttendee(customInputAttendee);
    console.log(customInputPurchaser, customInputAttendee);

    setTicket2({ ticket: data.tickets });
    console.log(configData);

    setLoading(false);
  };

  useEffect(() => {
    resizeIframe();
  }, [loading, page]);

  useEffect(() => {
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    // Initial fetchElementsConfig() call
    fetchElementsConfig().catch((err) => console.log("ERROR", err));
    // Call fetchElementsConfig() every 5 seconds to reload the data
  }, []);

  useEffect(() => {
    let timer;
    if (errorMessage != "") {
      timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);

  useEffect(() => {
    if (errorDisplayTime >= 3) {
      clearInterval(errorMessageInterval);
      setErrorDisplayTime(0);
      setErrorMessage("");
    }
  }, [errorDisplayTime]);

  const resizeIframe = () => {
    const height = document.body.scrollHeight;
    const width = document.body.scrollWidth;

    // Send the content height to the parent window
    window.parent.postMessage(
      {
        action: "RESIZE",
        entity: "CHARITYSTACK",
        sender: "EVENT",
        receiver: "HOSTED_SCRIPT",
        data: JSON.stringify({
          height: height,
          width: width,
          elementID: elementID,
          domID: domID,
        }),
      },
      "*",
    );
  };

  const hasErrors = () => {
    if (allAttendees.length == 0) {
      setErrorMessage("Please select one ticket");
      return true;
    }
    return false;
  };

  const handleButtonClick = () => {
    if (hasErrors()) {
      return;
    }
    if (
      (page === "START_PAGE" && customInputsValues.length > 0) ||
      customInputsValues == null
    ) {
      setPage("CUSTOM_INPUTS_PAGE");
    } else {
      setPage("ATTENDEE_0");
    }
  };

  const handlePurchase = () => {
    const data = configData;
    data.customInputsValues = customInputsValues;

    data.tickets = allAttendees;
    data.amount = amt;
    data.ticketMapping = map;
    data.frequency = "ONE_TIME";
    data.fund = data.formName;
    console.log("hostedPage", hostedPage);
    window.parent.postMessage(
      {
        action: "DONATE",
        entity: "CHARITYSTACK",
        sender: "EVENT",
        hostedPage: hostedPage,
        receiver: "HOSTED_SCRIPT",
        donationData: JSON.stringify(data),
      },
      "*",
    );
    console.log(data);
    // }, window.location.ancestorOrigins[0]);
  };

  useEffect(() => {
    var mp = [];
    var runningSum = 0;
    if (ticket2.ticket != undefined && ticket2.ticket.length > 0) {
      var t2 = ticket2.ticket;
      for (let i = 0; i < t2.length; i++) {
        // mp.push(t2[i].name)
        // mp.push([t2[i].soldNow, t2[i].soldNowPrice])
        mp.push({
          name: t2[i].name,
          numSold: parseInt(t2[i].soldNow),
          id: t2[i].id,
          soldTotalPrice: parseFloat(t2[i].soldNowPrice),
        });
        runningSum += t2[i].soldNowPrice;
      }
    }
    setAmt(parseFloat(runningSum));
    setMapping(mp);
  }, [ticket2, configData]);

  if (loading) {
    return (
      <>
        <div
          className={`mx-auto h-full max-w-sm items-center overflow-y-auto rounded-xl border border-gray-200 bg-white px-2 py-4 text-xs sm:text-sm`}
        >
          <CustomShimmer />
        </div>
      </>
    );
  } else {
    if (active) {
      return (
        <div data-name={elementName}>
          {page === "START_PAGE" && (
            <div
              className={`container mx-auto h-full items-center ${
                hostedPage !== null ? "max-w-lg" : "max-w-sm border-gray-200"
              }`}
            >
              <div
                className={`bg-white py-2 text-center text-gray-700 ${
                  hostedPage === null
                    ? "rounded-md rounded-b-none border-x border-t border-gray-200"
                    : ""
                }`}
              >
                Ticket Selection
              </div>

              <div
                className={`grid grid-cols-1 bg-white ${
                  hostedPage !== null
                    ? ""
                    : "rounded-md rounded-t-none border border-gray-200 px-6 pb-4 pt-2 sm:px-6"
                }`}
              >
                <div className="grid grid-cols-1 pb-2 pt-2 ">
                  {ticket2.ticket.map((t1, index) => {
                    var priceTicket = t1.price == 0 ? `Free` : `$${t1.price}`;
                    var amountSeats =
                      t1.seats > 1
                        ? `${t1.name} (${t1.seats}) - ${priceTicket}`
                        : `${t1.name} - ${priceTicket}`;
                    var type =
                      t1.seats == 0 || t1.seats == 1 ? "SINGLE" : "GROUP";
                    t1.seats = t1.seats === 0 ? 1 : t1.seats;

                    function onclick(event, ix) {
                      // create deep copy of dynamodbTickets
                      const newTickets = JSON.parse(
                        JSON.stringify(ticket2.ticket),
                      );
                      if (event.target.name == "plus") {
                        // if ( newTickets[index].quantity - newTickets[index].sold  == 0) {
                        //     //update button text to show error message
                        //     return;
                        // }

                        // update newTickets by grabbing the corresponding index and creating a new object under attendees. do this for how many seats there are
                        for (
                          let i = 0;
                          i < parseInt(newTickets[index].seats);
                          i++
                        ) {
                          var number = generateRandomId();
                          newTickets[index].attendees.push({
                            checkInStatus: false,
                            ticketName: newTickets[index].name,
                            ticketID: newTickets[index].id, //
                            type: type,
                            price: newTickets[index].price,
                            firstName: "",
                            lastName: "",
                            email: "",
                            confirmationID: number, // ticket that is bought
                            seats: newTickets[index].seats,
                            customInputAttendee: customInputsAttendee,
                          });
                        }
                        newTickets[index].soldNow =
                          newTickets[index].attendees.length /
                          parseInt(ticket2.ticket[index].seats);
                        newTickets[index].soldNowPrice =
                          (newTickets[index].attendees.length /
                            parseInt(ticket2.ticket[index].seats)) *
                          t1.price;
                        newTickets[index].sold++;
                      } else if (
                        event.target.name === "minus" &&
                        newTickets[index].attendees.length > 0
                      ) {
                        // subtract from newTickets by popping for numbner of seats
                        for (let i = 0; i < newTickets[index].seats; i++) {
                          newTickets[index].attendees.pop();
                        }
                        newTickets[index].soldNow =
                          newTickets[index].attendees.length /
                          parseInt(ticket2.ticket[index].seats);
                        newTickets[index].soldNowPrice =
                          (newTickets[index].attendees.length /
                            parseInt(ticket2.ticket[index].seats)) *
                          t1.price;
                        newTickets[index].sold--;
                      }

                      // update state
                      setTicket2({ ticket: newTickets });

                      // update allAttendees to be the combination of all attendees from all tickets
                      const newAllAttendees = [];
                      for (let i = 0; i < newTickets.length; i++) {
                        for (
                          let j = 0;
                          j < newTickets[i].attendees.length;
                          j++
                        ) {
                          newAllAttendees.push(newTickets[i].attendees[j]);
                        }
                      }

                      // single, single
                      // families, families, families, families, families

                      setAllAttendees(newAllAttendees); // single, single, families, families, families, families, families
                    }

                    function generateRandomId() {
                      const characters =
                        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                      let randomId = "";

                      for (let i = 0; i < 7; i++) {
                        const randomIndex = Math.floor(
                          Math.random() * characters.length,
                        );
                        randomId += characters.charAt(randomIndex);
                      }

                      return randomId;
                    }

                    return (
                      <div className="w-full pb-2">
                        {t1.quantity - t1.sold <= 0 ? (
                          <div>
                            <div className="mt-2 flex items-center rounded-t-lg border border-gray-300">
                              <span className=" inline-flex h-10 w-full items-center px-3 py-3 text-sm text-gray-500">
                                {amountSeats}
                              </span>
                              <div
                                className=" flex h-10  flex-row px-2"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className=" h-5 w-5 rounded-full bg-[#D2D9E0]"
                                  style={{ fontSize: 10, color: "black" }}
                                  name="minus"
                                  onClick={onclick}
                                >
                                  &mdash;
                                </button>
                                <input
                                  pattern="[0-9]*"
                                  type="number"
                                  className="w-10 bg-white text-center"
                                  type="text"
                                  value={
                                    ticket2.ticket[index].attendees.length /
                                    parseInt(ticket2.ticket[index].seats)
                                  }
                                  readonly
                                  disabled
                                  onChange={(event) => onclick(event, index)}
                                />
                                <button
                                  disabled
                                  className=" h-5 w-5 rounded-full bg-[#D2D9E0]"
                                  style={{ fontSize: 10, color: "black" }}
                                  name="plus"
                                  onClick={onclick}
                                >
                                  &#xff0b;
                                </button>
                              </div>
                            </div>
                            <div className="flex items-center rounded-b-lg border-x border-b  border-gray-300">
                              <span className=" inline-flex h-10 w-full items-center px-3 py-3 text-sm text-gray-500">
                                Total: $
                                {(
                                  (ticket2.ticket[index].attendees.length /
                                    parseInt(ticket2.ticket[index].seats)) *
                                  t1.price
                                ).toFixed(2)}
                              </span>
                              <div
                                className="w-30 mr-2 flex h-5 flex-row rounded-full bg-[#D2D9E0] px-2"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div className="w-30 flex h-5 flex-row flex-wrap rounded-full bg-[#D2D9E0]">
                                  <p
                                    className="flex-wrap"
                                    style={{
                                      padding: 2,
                                      width: 50,
                                      fontSize: 10,
                                      color: "black",
                                    }}
                                  >
                                    Sold Out
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="mt-2 flex items-center rounded-t-lg border border-gray-300">
                              <span className=" inline-flex h-10 w-full items-center px-3 py-3 text-sm text-gray-500">
                                {amountSeats}
                              </span>
                              <div
                                className=" flex h-10  flex-row px-2"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className=" h-5 w-5 rounded-full bg-[#D2D9E0]"
                                  style={{ fontSize: 10, color: "black" }}
                                  name="minus"
                                  onClick={onclick}
                                >
                                  &mdash;
                                </button>
                                <input
                                  pattern="[0-9]*"
                                  type="number"
                                  className="w-10 bg-white text-center"
                                  type="text"
                                  value={
                                    ticket2.ticket[index].attendees.length /
                                    parseInt(ticket2.ticket[index].seats)
                                  }
                                  readonly
                                  disabled
                                  onChange={(event) => onclick(event, index)}
                                />
                                <button
                                  className=" h-5 w-5 rounded-full bg-[#D2D9E0]"
                                  style={{ fontSize: 10, color: "black" }}
                                  name="plus"
                                  onClick={onclick}
                                >
                                  &#xff0b;
                                </button>
                              </div>
                            </div>
                            <div className="flex items-center rounded-b-lg border-x border-b  border-gray-300">
                              <span className=" inline-flex h-10 w-full items-center px-3 py-3 text-sm text-gray-500">
                                {(ticket2.ticket[index].attendees.length /
                                  parseInt(ticket2.ticket[index].seats)) *
                                  t1.price ==
                                  0 &&
                                ticket2.ticket[index].attendees.length /
                                  parseInt(ticket2.ticket[index].seats) !=
                                  0
                                  ? "Total: Free"
                                  : `Total: $${(
                                      (ticket2.ticket[index].attendees.length /
                                        parseInt(ticket2.ticket[index].seats)) *
                                      t1.price
                                    ).toFixed(2)}`}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="grid grid-cols-1 border-gray-200 bg-white ">
                  <button
                    type="button"
                    className={
                      "mt-4 h-10 rounded-md px-3.5 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" +
                      (errorMessage !== "" ? " text-sm" : " text-lg")
                    }
                    onClick={handleButtonClick}
                    style={{ backgroundColor: primaryColor, color: "white" }}
                  >
                    {errorMessage !== ""
                      ? errorMessage
                      : customInputsValues.length == 0
                      ? "Next"
                      : "Next"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {page.includes("ATTENDEE") && (
            <AttendeeInformationForm
              setPage={setPage}
              handleDonate={handlePurchase}
              primaryColor={primaryColor}
              allAttendees={allAttendees}
              setAllAttendees={setAllAttendees}
              ticket2={ticket2}
              setTicket2={setTicket2}
              customInputsValues={customInputsValues}
              setCustomInputsAttende={setCustomInputsAttendee}
              customInputsAttendee={customInputsAttendee}
              page={page}
              hostedPage={hostedPage}
            />
          )}
          {page === "CUSTOM_INPUTS_PAGE" && (
            <CustomInputsPageEvents
              customInputsValues={customInputsValues}
              setCustomInputsValues={setCustomInputsValues}
              setPage={setPage}
              primaryColor={primaryColor}
              hostedPage={hostedPage}
            />
          )}
        </div>
      );
    } else {
      return (
        <>
          <div className="container mx-auto h-full max-w-sm items-center">
            <div className="rounded-md rounded-b-none border-x border-t border-gray-200 bg-white py-2 text-center text-gray-500">
              Deactivated Form: {elementName}
            </div>
            <div className="grid h-64 grid-cols-1 rounded-md rounded-t-none border border-gray-200 bg-white px-6 pb-2 pt-2 sm:px-6"></div>
          </div>
        </>
      );
    }
  }
}

export default TicketSelectionForm;
