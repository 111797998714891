import React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function FundsDropdown({
  funds,
  selectedFund,
  setSelectedFund,
  elementID,
}) {
  return (
    <div
      className="relative inline-block w-full sm:w-full"
      id={`${elementID}-funds`}
    >
      <select
        onChange={(event) => setSelectedFund(event.target.value)}
        // className={customInput.answers[0] === "" ? "donor-field-select" : "donor-field-selected"}
        className={
          selectedFund == ""
            ? "h-10 w-full appearance-none rounded-md border border-gray-300 bg-white pl-2 text-base text-gray-500"
            : "h-10 w-full appearance-none rounded-md border border-gray-300 bg-white pl-2 text-base text-gray-900"
        }
        value={selectedFund}
        id={`${elementID}-selected-fund`}
      >
        <option
          className="w-full"
          value=""
          disabled
          selected
          id={`${elementID}-default-fund`}
        >
          Select Fund
        </option>
        {funds.map((fund, i) => (
          <option
            className="w-full"
            key={i}
            value={fund}
            id={`${elementID}-selectable-fund-${i}`}
          >
            {fund}
          </option>
        ))}
      </select>
      <span
        id={`${elementID}-funds-chevron-down-container`}
        className="pointer-events-none absolute right-2 top-1/2 z-10 -translate-y-1/2 transform"
      >
        <ChevronDownIcon
          className="h-6 w-6"
          id={`${elementID}-funds-chevron-down-icon`}
        />
      </span>
    </div>
  );
}
