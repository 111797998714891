import React from "react";
import { useEffect, useState } from "react";
import { DonorListShimmer } from "./Util/Shimmer";

function DonorList() {
  function addCommas(number) {
    if (typeof number !== "number") {
      throw new Error("Input must be a number.");
    }

    // Convert the number to a string
    let numberStr = number.toString();

    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = numberStr.split(".");

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return integerPart;
  }

  // write a function that takes a string timestamp formatted as 2023-08-02 04:37:29.897884
  // and converts it to a format like April 30th, 2023 at 7:52pm in the user's local timezone
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const timezoneOffsetInMinutes = date.getTimezoneOffset();

    // Apply the timezone offset to the date
    date.setMinutes(date.getMinutes() - timezoneOffsetInMinutes);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate;
  }

  const [hostedPage, setHostedPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [elementID, setElementID] = useState("");
  const [elementName, setElementName] = useState("");
  const [listType, setListType] = useState("RECENT_DONORS");
  const [donations, setDonations] = useState([]);

  const [domID, setDomID] = useState("");

  // create an async useEffect to fetch elements config
  const fetchElementsConfig = async () => {
    // Grab elements config using elementID in query params
    const urlParams = new URLSearchParams(window.location.search);
    // if elementID is not in query params, return a hardcoded one
    let elementID = urlParams.get("elementID");

    if (elementID == null) {
      elementID =
        process.env.REACT_APP_ENVIRONMENT == "development"
          ? "dd85afac-0cc8-4a01-88ea-72cfd862cf86"
          : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    }
    setElementID(elementID);

    const response = await fetch(
      `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`,
    );
    const data = await response.json();

    if (data.elementType != "DONOR_LIST") {
      throw new Error("Wrong element type");
    }
    setActive(data.active);
    setDonations(data.donations);
    setElementName(data.elementName);
    setListType(data.listType);
    setLoading(false);
  };

  useEffect(() => {
    resizeIframe();
    console.log("SENDING");

    if (loading === false && hostedPage !== null) {
      // send message that element has finished loading
      window.parent.postMessage(
        {
          action: "FINISHED_LOADING",
          entity: "CHARITYSTACK",
          sender: "DONOR_LIST",
          receiver: "HOSTED_PAGE",
        },
        "*",
      );
    }
  }, [loading]);

  useEffect(() => {
    // Initial fetchElementsConfig() call
    fetchElementsConfig().catch((err) => console.log("ERROR", err));
    // grab hostedPage boolean
    const urlParams = new URLSearchParams(window.location.search);
    const hostedPage = urlParams.get("page");
    // page can be either null, HOSTED_PAGE_FULLSCREEN, or HOSTED_PAGE_POPUP
    setHostedPage(hostedPage);
    setDomID(urlParams.get("domID"));

    // Call fetchElementsConfig() every 5 seconds to reload the data
    const interval = setInterval(() => {
      fetchElementsConfig().catch((err) => console.log("ERROR", err));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const resizeIframe = () => {
    const height = document.body.scrollHeight;
    const width = document.body.scrollWidth;

    // Send the content height to the parent window
    window.parent.postMessage(
      {
        action: "RESIZE",
        entity: "CHARITYSTACK",
        sender: "FUNDRAISING_BAR",
        receiver: "HOSTED_SCRIPT",
        data: JSON.stringify({
          height: height,
          width: width,
          elementID: elementID,
          domID: domID,
        }),
      },
      "*",
    );
  };

  if (loading) {
    return (
      <>
        <div
          className={`mx-auto h-[480px] max-w-sm items-center overflow-y-auto rounded-md border border-gray-200 bg-white px-6 py-4 text-xs sm:text-sm`}
        >
          <DonorListShimmer />
        </div>
      </>
    );
  }
  if (!loading) {
    if (active) {
      return (
        <div data-name={elementName}>
          <div
            className={`mx-auto items-center rounded-md border border-gray-200 bg-white py-4 text-xs sm:text-sm ${
              hostedPage !== null ? "h-full w-full" : "h-full max-w-sm"
            }`}
          >
            <div
              className={`px-6 text-black ${
                hostedPage !== null ? "text-lg sm:text-2xl" : "text-2xl"
              }`}
            >
              {listType === "RECENT_DONORS"
                ? "Recent Supporters"
                : "Top Supporters"}
            </div>
            <div className="my-2 border-b border-gray-300 px-6"></div>
            <div
              className={`overflow-y-auto pl-6 ${
                hostedPage !== null
                  ? "h-[120px] w-full md:h-[250px]"
                  : "h-[480px] max-w-sm"
              }`}
            >
              {donations.map((donation, index) => {
                // shorten donation.name if it's too long
                if (
                  donation.name.length +
                    addCommas(donation.grossAmount).length >
                  20
                ) {
                  donation.name = donation.name.split(" ")[0];
                }

                // make donation.name cased properly
                donation.name = donation.name
                  .split(" ")
                  .map((word) => {
                    return (
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    );
                  })
                  .join(" ");

                return (
                  <div className="pb-[1px]">
                    <div
                      className={`flex text-gray-900 ${
                        hostedPage !== null ? "text-base sm:text-lg" : "text-lg"
                      }`}
                    >
                      {/* Check if the length of the below text exceeds the parent width */}
                      <div>{donation.name} donated&nbsp;</div>
                      <div className="font-bold">
                        ${addCommas(donation.grossAmount)}
                      </div>
                    </div>
                    <div
                      className={`-mt-[5px] text-gray-500 ${
                        hostedPage !== null ? "text-2xs sm:text-sm" : "text-sm"
                      }`}
                    >
                      {formatTimestamp(donation.timestamp)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {hostedPage !== null ? (
            <></>
          ) : (
            <div
              className={`mx-auto h-[480px] max-w-sm items-center overflow-y-auto rounded-md border border-gray-200 bg-white px-6 py-4 text-xs sm:text-sm`}
            >
              Deactivated Element: {elementName}
            </div>
          )}
        </>
      );
    }
  }
}

export default DonorList;
