import React, { useEffect, useState } from "react";
import { HeartIcon } from "@heroicons/react/24/solid";
import { DonationFlagShimmer } from "./Util/Shimmer";
import "../CustomCSS/shine.css";

const freqMap = {
  "": "",
  ONE_TIME: "One-Time",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ANNUALLY: "Yearly",
};

function DonationFlag(props) {
  const [elementID, setElementID] = useState("");
  const [loading, setLoading] = useState(true);

  const [destinationURL, setDestinationURL] = useState(
    process.env.REACT_APP_ENVIRONMENT == "production"
      ? "https://donate.charitystack.com"
      : "https://dev.d3qaovrr6ze9o9.amplifyapp.com",
    // "localhost:3002",
  );
  const [color, setColor] = useState("#538CAC");
  const [orientation, setOrientation] = useState("RIGHT");
  const [destinationType, setDestinationType] = useState("POPUP");

  // const listener = (event) => {
  //     if (!(event.data.entity == 'CHARITYSTACK' && event.data.action == 'RESET_ELEMENT')) return;

  //     setPage('START_PAGE')
  //     setFrequency('')
  //     setAmount('')
  //     setSelectedFund('')

  //     fetchElementsConfig().catch(err => console.log('ERROR', err))
  // }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const elementID = urlParams.get("elementID");
    setColor(urlParams.get("color"));
    // setDestinationURL(urlParams.get("destinationURL"));
    setDestinationURL(`${destinationURL}/${elementID}`);
    setOrientation(urlParams.get("orientation"));
    setLoading(false);
    setElementID(elementID);
    setDestinationType(urlParams.get("destinationType"));
    // fetchElementsConfig().catch(err => console.log('ERROR', err))
    // window.addEventListener('message', listener)
    // return () => {
    //     window.removeEventListener('message', listener)
    // }
  }, []);

  const handleClick = () => {
    // Send the content height to the parent window
    console.log("CLICKED", destinationType);
    if (destinationType == "POPUP") {
      window.parent.postMessage(
        {
          action: "SHOW_HOSTED_PAGE_POPUP",
          entity: "CHARITYSTACK",
          sender: "DONATION_FLAG",
          receiver: "HOSTED_SCRIPT",
        },
        "*",
      );
    } else {
      window.parent.postMessage(
        {
          action: "REDIRECT",
          entity: "CHARITYSTACK",
          sender: "DONATION_FLAG",
          receiver: "HOSTED_SCRIPT",
          data: JSON.stringify({
            elementID: elementID,
            destinationURL: destinationURL,
          }),
        },
        "*",
      );
    }
  };

  if (loading) {
    return (
      <>
        <div className="h-8 w-24 rounded">
          <DonationFlagShimmer />
        </div>
      </>
    );
  }
  if (!loading) {
    return (
      <>
        <button
          className={`flex h-10 w-28 cursor-pointer select-none items-center justify-center rounded ${
            orientation == "TOP" ? "rounded-t-none" : "rounded-b-none"
          }`}
          style={{ backgroundColor: color }}
          onClick={handleClick}
        >
          <div className="text-sm font-medium text-white">Donate</div>
          <div>
            <HeartIcon
              className="h-4 w-4 pl-[2px] text-white"
              style={{ color: "#FFFFFF" }}
            />
          </div>
        </button>
      </>
    );
  }
}

export default DonationFlag;
