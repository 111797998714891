import React, { Component, useEffect, useState } from "react";
import deepCopy from "./DeepCopy";
import { ChevronLeftIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
//import MultiSelectDropdown from './utils/MultiSelectDropdown'

export default function AttendeeInformationForm({
  handleDonate,
  allAttendees,
  setAllAttendees,
  ticket2,
  customInputsValues,
  setPage,
  page,
  hostedPage,
  primaryColor,
  customInputsAttendee,
  setCustomInputsAttendee,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDisplayTime, setErrorDisplayTime] = useState(0);
  const [errorMessageInterval, setErrorMessageInterval] = useState();
  const [disabled, setDisabled] = useState(false);
  const [list, setList] = useState(new Set());
  const [pageIndex, setPageIndex] = useState(0);
  const [pageType, setPageType] = useState("Single");

  // create a list that is the combination of all the attendees from all the tickets

  useEffect(() => {
    var attendees_All = [];
    for (let i = 0; i < ticket2.length; i++) {
      for (let j = 0; j < ticket2[i].attendees.length; j++) {
        attendees_All.push(ticket2[i].attendees[j]);
      }
    }
    console.log(allAttendees);
    // setAllAttendees(attendees_All);
  }, []);
  useEffect(() => {
    console.log(errorDisplayTime);
    if (errorDisplayTime >= 3) {
      console.log("cleared");
      clearInterval(errorMessageInterval);
      setErrorDisplayTime(0);
      setErrorMessage("");
    }
    // setAllAttendees(attendees_All);
  }, [errorDisplayTime]);

  useEffect(() => {
    let timer;
    if (errorMessage != "") {
      timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);

  // Specific to hosted event page, which doesnt use the normal back button
  // We read in the page change from the parent component and update the page index accordingly
  // We only update for back events, not for next events
  useEffect(() => {
    if (hostedPage !== null) {
      // extract current page index from page
      let currentPageIndex = parseInt(page.split("_")[1]);
      if (currentPageIndex < pageIndex) {
        setPageIndex(currentPageIndex);
      }
    }
  }, [page]);

  const handleClick = () => {
    if (
      allAttendees[pageIndex].firstName === "" ||
      allAttendees[pageIndex].lastName === "" ||
      allAttendees[pageIndex].email === ""
    ) {
      setErrorMessage("Some required fields are missing");
      return;
    } else {
      let pI = pageIndex;
      setPageIndex(pageIndex + 1);
      setPage(`ATTENDEE_${pI + 1}`);
    }
  };

  const handleClickDonate = () => {
    if (allAttendees[pageIndex].customInputAttendee !== undefined) {
      for (
        let i = 0;
        i < allAttendees[pageIndex].customInputAttendee.length;
        i++
      ) {
        console.log(allAttendees[pageIndex].customInputAttendee[i]);
        if (
          allAttendees[pageIndex].customInputAttendee[i].required == true &&
          allAttendees[pageIndex].customInputAttendee[i].answers[0] === "" &&
          (allAttendees[pageIndex].customInputAttendee[i].type ===
            "SINGLE_SELECTION_DROPDOWN" ||
            allAttendees[pageIndex].customInputAttendee[i].type ===
              "SHORT_TEXT_INPUT" ||
            allAttendees[pageIndex].customInputAttendee[i].type ===
              "LONG_TEXT_INPUT")
        ) {
          setErrorMessage("Some required fields are missing");
          return;
        }
      }
    }
    if (
      allAttendees[pageIndex].firstName === "" ||
      allAttendees[pageIndex].lastName === "" ||
      allAttendees[pageIndex].email === ""
    ) {
      setErrorMessage("Some required fields are missing");
      return;
    } else {
      handleDonate();
    }
  };

  // onChangeFirstName
  const onChangeFirstName = (event, index) => {
    const newAllAttendees = JSON.parse(JSON.stringify(allAttendees));
    newAllAttendees[index].firstName = event.target.value;
    setAllAttendees(newAllAttendees);
  };

  // onChangeLastName
  const onChangeLastName = (event, index) => {
    const newAllAttendees = JSON.parse(JSON.stringify(allAttendees));
    newAllAttendees[index].lastName = event.target.value;
    setAllAttendees(newAllAttendees);
  };

  const onChangeEmail = (event, index) => {
    const newAllAttendees = JSON.parse(JSON.stringify(allAttendees));
    newAllAttendees[index].email = event.target.value;
    setAllAttendees(newAllAttendees);
  };

  const handleShortTextAnswerChange = (event, index, index1) => {
    const newAllAttendees = JSON.parse(JSON.stringify(allAttendees));
    const newCutomInputsAttendee = deepCopy(
      allAttendees[index].customInputAttendee,
    );
    newCutomInputsAttendee[index1].answers = [event.target.value];

    newAllAttendees[index].customInputAttendee = newCutomInputsAttendee;
    setAllAttendees(newAllAttendees);
  };

  const handleLongTextAnswerChange = (event, index, index1) => {
    const newAllAttendees = JSON.parse(JSON.stringify(allAttendees));
    const newCutomInputsAttendee = deepCopy(
      allAttendees[index].customInputAttendee,
    );
    newCutomInputsAttendee[index1].answers = [event.target.value];

    newAllAttendees[index].customInputAttendee = newCutomInputsAttendee;
    setAllAttendees(newAllAttendees);
  };

  const handleSingleSelectDropdownAnswerChange = (event, index, index1) => {
    const newAllAttendees = JSON.parse(JSON.stringify(allAttendees));
    const newCutomInputsAttendee = deepCopy(
      allAttendees[index].customInputAttendee,
    );
    newCutomInputsAttendee[index1].answers = [event.target.value];

    newAllAttendees[index].customInputAttendee = newCutomInputsAttendee;
    setAllAttendees(newAllAttendees);
  };

  const handleMultiSelectDropdownAnswerChange = (event, index, index1) => {
    // TODO: Implement
  };

  return (
    <>
      <div
        className={`container mx-auto h-full items-center ${
          hostedPage !== null ? "max-w-lg" : "max-w-sm"
        }`}
      >
        <div
          className={`flex bg-white px-2 py-2 text-gray-500 ${
            hostedPage === null
              ? "rounded-md rounded-b-none border-x border-t border-gray-200"
              : ""
          }`}
        >
          {hostedPage === null && (
            <ChevronLeftIcon
              className="h-5 w-5 flex-none cursor-pointer self-start text-gray-400"
              aria-hidden="true"
              onClick={() => {
                if (pageIndex == 0) {
                  if (customInputsValues.length > 0) {
                    setPage("CUSTOM_INPUTS_PAGE");
                  } else {
                    setPage("START_PAGE");
                  }
                } else {
                  let pI = pageIndex;
                  setPageIndex(pageIndex - 1);
                  setPage(`ATTENDEE_${pI - 1}`);
                }
              }}
            />
          )}
          <div className="flex flex-auto justify-center pr-5 text-center text-gray-700">
            Attendee {pageIndex + 1} Information |{" "}
            {allAttendees[pageIndex].ticketName}
          </div>
        </div>

        <div
          className={`grid grid-cols-1 bg-white ${
            hostedPage !== null
              ? ""
              : "rounded-md rounded-t-none border border-gray-200 px-6 pb-4 pt-2 sm:px-6"
          }`}
        >
          {allAttendees.map((attendee, index) => {
            if (index == pageIndex) {
              return (
                <div className="pb-2">
                  <div className="relative pb-2">
                    <div className="ml-[2px]">
                      <label
                        for={"first-name-input-" + attendee.firstName}
                        className="text-sm text-gray-500"
                      >
                        Attendee First Name
                      </label>
                      <span style={{ color: "red" }}>&nbsp;*</span>
                    </div>
                    <input
                      type="text"
                      name={"First Name" + attendee.firstName}
                      id={"first-name-input-" + attendee.firstName}
                      className="border-1 peer block h-10 w-full appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                      placeholder=" "
                      value={attendee.firstName}
                      autoComplete="given-name"
                      onChange={(event) => onChangeFirstName(event, index)}
                      maxLength="280"
                    />
                  </div>

                  <div className="relative pb-2">
                    <div className="ml-[2px]">
                      <label
                        for={"last-name-input-" + attendee.lastName}
                        className="text-sm text-gray-500"
                      >
                        Attendee Last Name
                      </label>
                      <span style={{ color: "red" }}>&nbsp;*</span>
                    </div>
                    <input
                      type="text"
                      name={"Last Name" + attendee.lastName}
                      id={"last-name-input-" + attendee.firstName}
                      className="border-1 peer block h-10 w-full appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                      placeholder=" "
                      autoComplete="family-name"
                      value={attendee.lastName}
                      onChange={(event) => onChangeLastName(event, index)}
                      maxLength="280"
                    />
                  </div>
                  <div className="relative pb-2">
                    <div className="ml-[2px]">
                      <label
                        for={"email-input-" + attendee.email}
                        className="text-sm text-gray-500"
                      >
                        Email
                      </label>
                      <span style={{ color: "red" }}>&nbsp;*</span>
                    </div>
                    <input
                      type="text"
                      name={"Email" + attendee.email}
                      id={"email-input-" + attendee.email}
                      className="border-1 peer block h-10 w-full appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                      placeholder=" "
                      autoComplete="email"
                      value={attendee.email}
                      onChange={(event) => onChangeEmail(event, index)}
                      maxLength="280"
                    />
                  </div>

                  {attendee.customInputAttendee.map((customInput, index1) => {
                    return (
                      <div className="pb-2">
                        {customInput.type === "SINGLE_SELECTION_DROPDOWN" && (
                          <div
                            className="relative inline-block w-full"
                            key={index1}
                          >
                            <div className="ml-[2px]">
                              <label
                                for={"short-text-input-" + customInput.title}
                                className="text-sm text-gray-500"
                              >
                                {customInput.title}
                                {customInput.required && (
                                  <span style={{ color: "red" }}>&nbsp;*</span>
                                )}
                              </label>
                            </div>
                            <div className="relative">
                              <select
                                onChange={(event) =>
                                  handleSingleSelectDropdownAnswerChange(
                                    event,
                                    index,
                                    index1,
                                  )
                                }
                                // className={customInput.answers[0] === "" ? "donor-field-select" : "donor-field-selected"}
                                className={
                                  customInput.answers[0] == ""
                                    ? "h-10 w-full appearance-none rounded-md border border-gray-300 bg-white pl-2 text-sm text-gray-500"
                                    : "h-10 w-full appearance-none rounded-md border border-gray-300 bg-white pl-2 text-sm text-gray-900"
                                }
                                value={customInput.answers[0]}
                              >
                                <option className="" value="" disabled selected>
                                  Select an option
                                  {customInput.required && " *"}
                                </option>
                                {customInput.options.map((option, i) => (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <ChevronDownIcon className="pointer-events-none absolute right-0 top-0 mr-2 mt-2 h-6 w-6" />
                            </div>
                          </div>
                        )}
                        {customInput.type === "SHORT_TEXT_INPUT" && (
                          <div className="relative" key={index1}>
                            <div className="ml-[2px]">
                              <label
                                for={"short-text-input-" + customInput.title}
                                className="text-sm text-gray-500"
                              >
                                {customInput.title}
                                {customInput.required && (
                                  <span style={{ color: "red" }}>&nbsp;*</span>
                                )}
                              </label>
                            </div>
                            <input
                              type="text"
                              name={"short-text-input-" + customInput.title}
                              id={"short-text-input-" + customInput.title}
                              className="border-1 peer block h-10 w-full appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                              placeholder=" "
                              value={customInput.answers[0]}
                              onChange={(event) =>
                                handleShortTextAnswerChange(
                                  event,
                                  index,
                                  index1,
                                )
                              }
                              maxLength="280"
                            />
                          </div>
                        )}
                        {customInput.type === "LONG_TEXT_INPUT" && (
                          <div className="relative pb-2" key={index1}>
                            <div className="ml-[2px]">
                              <label
                                for={"long-text-input-" + customInput.title}
                                className="text-sm text-gray-500"
                              >
                                {customInput.title}
                                {customInput.required && (
                                  <span style={{ color: "red" }}>&nbsp;*</span>
                                )}
                              </label>
                            </div>
                            <textarea
                              type="text"
                              name={"long-text-input-" + customInput.title}
                              id={"long-text-input-" + customInput.title}
                              className="border-1 peer block h-24 w-full appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                              placeholder=" "
                              value={customInput.answers[0]}
                              onChange={(event) =>
                                handleLongTextAnswerChange(event, index, index1)
                              }
                              maxLength="1000"
                            />
                          </div>
                        )}

                        {/* TODO: Add multi select dropdown */}
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
          <button
            type="button"
            className={
              "mt-4 h-10 rounded-md px-3.5 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" +
              (errorMessage !== "" ? " text-sm" : " text-lg")
            }
            onClick={() => {
              if (pageIndex == allAttendees.length - 1) {
                handleClickDonate();
              } else {
                handleClick();
              }
            }}
            style={{ backgroundColor: primaryColor, color: "white" }}
          >
            {errorMessage === ""
              ? pageIndex === allAttendees.length - 1
                ? allAttendees.reduce(
                    (sum, ticket) => sum + ticket.price,
                    0,
                  ) === 0
                  ? "Checkout"
                  : "Pay"
                : "Next"
              : errorMessage}
          </button>
        </div>
      </div>
    </>
  );
}
