import React, { useEffect, useState, Suspense, lazy } from "react";
import CustomShimmer from "./Util/Shimmer";

const DonationFrequencies = lazy(() => import("./Util/DonationFrequencies"));
const CustomAmount = lazy(() => import("./Util/CustomAmount"));
const FundsDropdown = lazy(() => import("./Util/FundsDropdown"));
const AmountButtons = lazy(() => import("./Util/AmountButtons"));
const CustomInputsPage = lazy(() => import("./Util/CustomInputs"));
const CrowdfundingForm = lazy(() => import("./CrowdfundingForm"));
const StandardForm = lazy(() => import("./StandardForm"));
const QuickDonate = lazy(() => import("./QuickDonate"));
const AddToCart = lazy(() => import("./AddToCart"));
const ProductForm = lazy(() => import("./ProductForm"));

const freqMap = {
  "": "",
  ONE_TIME: "One-Time",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ANNUALLY: "Yearly",
};

function EmbedForm() {
  const [data, setData] = useState(null);

  // create an async useEffect to fetch elements config
  const fetchElementsConfig = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let elementID = urlParams.get("elementID");

    if (!elementID) {
      elementID =
        process.env.REACT_APP_ENVIRONMENT === "development"
          ? "d19c007c-6dae-48e9-9809-54905bc6ddaa"
          : "318bfedb-b2ca-497a-8b7f-9d0cccf6fb0c";
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/elements-config?elementID=${elementID}`
      );
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    fetchElementsConfig();
  }, []);

  if (!data) {
    return (
      <div className="container mx-auto h-full max-w-sm items-center">
        <div className="rounded-md rounded-b-none border-x border-t border-gray-200 bg-white py-2 text-center text-gray-500">
          Loading
        </div>
        <div className="grid grid-cols-1 rounded-md rounded-t-none border border-gray-200 bg-white px-6 pb-4 pt-2 sm:px-6">
          <CustomShimmer />
        </div>
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="container mx-auto h-full max-w-sm items-center">
          <div className="rounded-md rounded-b-none border-x border-t border-gray-200 bg-white py-2 text-center text-gray-500">
            Loading
          </div>
          <div className="grid grid-cols-1 rounded-md rounded-t-none border border-gray-200 bg-white px-6 pb-4 pt-2 sm:px-6">
            <CustomShimmer />
          </div>
        </div>
      }
    >
      {data.formType === "EMBED_FORM" && (
        <StandardForm data={data} fetchElementsConfig={fetchElementsConfig} />
      )}
      {data.formType === "CROWDFUNDING_FORM" && (
        <CrowdfundingForm data={data} fetchElementsConfig={fetchElementsConfig} />
      )}
      {data.formType === "QUICK_DONATE" && (
        <QuickDonate data={data} fetchElementsConfig={fetchElementsConfig} />
      )}
      {data.formType === "PRODUCT" && (
        <ProductForm data={data} fetchElementsConfig={fetchElementsConfig} />
      )}
    </Suspense>
  );
}

export default EmbedForm;
