import React, { Component } from "react";
import deepCopy from "./DeepCopy";
import { ChevronLeftIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
//import MultiSelectDropdown from './utils/MultiSelectDropdown'

class CustomInputsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      errorDisplayTime: 0,
      errorMessageInterval: null,
      disabled: false,
    };
  }

  componentDidUpdate() {
    const { errorMessageInterval, errorDisplayTime } = this.state;
    // // // console.log('error display time', errorDisplayTime)
    // // // console.log(errorMessageInterval)
    if (errorDisplayTime >= 3) {
      clearInterval(errorMessageInterval);
      this.setState({
        errorDisplayTime: 0,
        errorMessage: "",
      });
    }
  }

  handleClick = () => {
    const { customInputsValues, handleDonate } = this.props;
    for (let i = 0; i < customInputsValues.length; i++) {
      if (
        customInputsValues[i].answers[0] === "" &&
        customInputsValues[i].required == true &&
        (customInputsValues[i].type === "SINGLE_SELECTION_DROPDOWN" ||
          customInputsValues[i].type === "SHORT_TEXT_INPUT" ||
          customInputsValues[i].type === "LONG_TEXT_INPUT")
      ) {
        this.setState({
          errorMessage: `Some required fields are missing`,
          errorDisplayTime: 0,
          errorMessageInterval: setInterval(
            () =>
              this.setState({
                errorDisplayTime: this.state.errorDisplayTime + 1,
              }),
            1000,
          ),
        });
        return;
      }
      // TODO: Add error-checking for multi select dropdown
    }

    handleDonate();
  };

  handleShortTextAnswerChange = (event, index) => {
    const newCustomInputsValues = deepCopy(this.props.customInputsValues);
    newCustomInputsValues[index].answers = [event.target.value];
    // console.log('newCustomInputsValues: ', newCustomInputsValues)
    this.props.setCustomInputsValues(newCustomInputsValues);
  };

  handleLongTextAnswerChange = (event, index) => {
    const newCustomInputsValues = deepCopy(this.props.customInputsValues);
    newCustomInputsValues[index].answers = [event.target.value];
    // console.log('newCustomInputsValues: ', newCustomInputsValues)
    this.props.setCustomInputsValues(newCustomInputsValues);
  };

  handleSingleSelectDropdownAnswerChange = (event, index) => {
    const newCustomInputsValues = deepCopy(this.props.customInputsValues);
    newCustomInputsValues[index].answers = [event.target.value];
    // console.log('newCustomInputsValues: ', newCustomInputsValues)
    this.props.setCustomInputsValues(newCustomInputsValues);
  };

  handleMultiSelectDropdownAnswerChange = (event, index) => {
    // TODO: Implement
  };

  render() {
    const { errorMessage } = this.state;
    const { primaryColor } = this.props;
    return (
      <>
        <div
          className={`container mx-auto h-full items-center ${
            this.props.hostedPage !== null ? "max-w-lg" : "max-w-sm"
          }`}
        >
          {this.props.hostedPage === null && (
            <div className="flex rounded-md rounded-b-none border-x border-t border-gray-200 bg-white px-2 py-2 text-gray-500">
              <ChevronLeftIcon
                className="h-5 w-5 flex-none cursor-pointer self-start text-gray-400"
                aria-hidden="true"
                onClick={() => this.props.setPage("START_PAGE")}
              />
              <div className="flex flex-auto justify-center pr-5 text-center text-gray-700">
                Additional Information
              </div>
            </div>
          )}
          <div
            className={`grid grid-cols-1 bg-white ${
              this.props.hostedPage !== null
                ? ""
                : "rounded-md rounded-t-none border border-gray-200 px-6 pb-4 pt-2 sm:px-6"
            }`}
          >
            {this.props.customInputsValues.map((customInput, index) => {
              return (
                <div className="pb-2">
                  {customInput.type === "SINGLE_SELECTION_DROPDOWN" && (
                    <div className="relative inline-block w-full" key={index}>
                      <div className="ml-[2px]">
                        <label
                          for={"short-text-input-" + customInput.title}
                          className="text-sm text-gray-500"
                        >
                          {customInput.title}
                          {customInput.required && (
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          )}
                        </label>
                      </div>
                      <div className="relative">
                        <select
                          onChange={(event) =>
                            this.handleSingleSelectDropdownAnswerChange(
                              event,
                              index,
                            )
                          }
                          // className={customInput.answers[0] === "" ? "donor-field-select" : "donor-field-selected"}
                          className={
                            customInput.answers[0] == ""
                              ? "h-10 w-full appearance-none rounded-md border border-gray-300 bg-white pl-2 text-sm text-gray-500"
                              : "h-10 w-full appearance-none rounded-md border border-gray-300 bg-white pl-2 text-sm text-gray-900"
                          }
                          value={customInput.answers[0]}
                        >
                          <option className="" value="" disabled selected>
                            Select an option
                            {customInput.required && " *"}
                          </option>
                          {customInput.options.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <ChevronDownIcon className="pointer-events-none absolute right-0 top-0 mr-2 mt-2 h-6 w-6" />
                      </div>
                    </div>
                  )}
                  {customInput.type === "SHORT_TEXT_INPUT" && (
                    <div className="relative" key={index}>
                      <div className="ml-[2px]">
                        <label
                          for={"short-text-input-" + customInput.title}
                          className="text-sm text-gray-500"
                        >
                          {customInput.title}
                          {customInput.required && (
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          )}
                        </label>
                      </div>
                      <input
                        type="text"
                        name={"short-text-input-" + customInput.title}
                        id={"short-text-input-" + customInput.title}
                        className="border-1 peer block h-10 w-full appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                        placeholder=" "
                        value={customInput.answers[0]}
                        onChange={(event) =>
                          this.handleShortTextAnswerChange(event, index)
                        }
                        maxLength="280"
                      />
                    </div>
                  )}
                  {customInput.type === "LONG_TEXT_INPUT" && (
                    <div className="relative" key={index}>
                      <div className="ml-[2px]">
                        <label
                          for={"long-text-input-" + customInput.title}
                          className="text-sm text-gray-500"
                        >
                          {customInput.title}
                          {customInput.required && (
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          )}
                        </label>
                      </div>
                      <textarea
                        type="text"
                        name={"long-text-input-" + customInput.title}
                        id={"long-text-input-" + customInput.title}
                        className="border-1 peer block h-24 w-full resize-none appearance-none rounded-md border border-gray-300 bg-transparent px-[7px] py-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                        placeholder=" "
                        value={customInput.answers[0]}
                        onChange={(event) =>
                          this.handleLongTextAnswerChange(event, index)
                        }
                        maxLength="1000"
                      />
                    </div>
                  )}

                  {/* TODO: Add multi select dropdown */}
                </div>
              );
            })}
            <button
              type="button"
              className={
                "mt-4 h-10 rounded-md px-3.5 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" +
                (errorMessage !== "" ? " text-sm" : " text-lg")
              }
              onClick={this.handleClick}
              style={{ backgroundColor: primaryColor, color: "white" }}
            >
              {errorMessage === ""
                ? this.props.addToCart === true
                  ? "Add To Cart"
                  : "Next"
                : errorMessage}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CustomInputsPage;
