import { useEffect } from "react";

const freqMap = {
  "": "",
  ONE_TIME: "One-Time",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ANNUALLY: "Yearly",
};

const reverseFreqMap = {
  "": "",
  "One-Time": "ONE_TIME",
  Daily: "DAILY",
  Weekly: "WEEKLY",
  Monthly: "MONTHLY",
  Yearly: "ANNUALLY",
};

const freqsLenToWidth = {
  2: "w-1/2",
  3: "w-1/3",
  4: "w-1/4",
  5: "w-1/5",
  6: "w-1/6",
};

export default function DonationFrequencies({
  frequencies,
  frequency,
  setFrequency,
  primaryColor,
  secondaryColor,
  elementID,
}) {
  // cannot use tailwind b/c colors are dynamic
  // and tailwind cannot load it if not available
  // at compile time
  const getButtonColorStyling = (buttonText) => {
    // bg-[${secondaryColor}]
    // console.log('SECONDARY', `${secondaryColor}`)
    //
    const color = "#1da1f2";
    if (freqMap[frequency] === buttonText) {
      return { backgroundColor: primaryColor, color: "white" };
    }
    return { backgroundColor: "white", color: "gray" };
  };

  if (frequencies.length == 0 || frequencies.length == 1) {
    return <></>;
  } else {
    return (
      <span
        className={`isolate inline-flex h-10 w-full rounded-md border border-gray-200 bg-gray-100 px-2 py-1 shadow-sm ${
          frequencies.length < 5
            ? "text-xs default:text-xs" // 1-4
            : frequencies.length === 5
            ? "text-2xs default:text-xs" // 5
            : "text-2xs default:text-2xs" // 6
        }`}
        id={`${elementID}-frequencies`}
      >
        {frequencies.map((buttonFreq, i) => {
          return (
            <button
              type="button"
              className={
                "relative items-center rounded-md py-1 text-gray-500 focus:z-10 sm:hover:bg-gray-200 " +
                freqsLenToWidth[frequencies.length] +
                (buttonFreq === frequency
                  ? " text-white"
                  : " bg-gray-100 text-gray-500")
              }
              style={
                buttonFreq === frequency
                  ? { backgroundColor: primaryColor }
                  : {}
              }
              onClick={() => setFrequency(buttonFreq)}
              id={`${elementID}-frequencies-button-${i}`}
              // style={getButtonColorStyling(freqMap[freq])}
            >
              {freqMap[buttonFreq]}
            </button>
          );
        })}
      </span>
    );
  }
}
