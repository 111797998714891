import React from "react";
import ContentLoader from "react-content-loader";

const CustomShimmer = () => {
  return (
    <div style={{ width: "100%" }}>
      <ContentLoader
        // viewBox="0 0 400 200"
        width={"100%"}
        height={"250"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="15" y="15" rx="2" ry="2" width="100%" height="40" />

        <rect x="15" y="63" rx="2" ry="2" width="100%" height="40" />
        <rect x="15" y="111" rx="2" ry="2" width="100%" height="40" />
        <rect x="15" y="159" rx="2" ry="2" width="100%" height="40" />
        <rect x="15" y="207" rx="2" ry="2" width="100%" height="40" />
      </ContentLoader>
    </div>
  );
};

export const DonationFlagShimmer = () => {
  return (
    <div style={{ width: "100%" }}>
      <ContentLoader
        // viewBox="0 0 400 200"
        width={"100%"}
        height={"32px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="4" y="4" rx="2" ry="2" width="100%" height="32px" />
      </ContentLoader>
    </div>
  );
};

export const QuickDonateShimmer = () => {
  return (
    <div style={{ width: "100%" }}>
      <ContentLoader
        // viewBox="0 0 400 200"
        width={"100%"}
        height={"40"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="2" ry="2" width="100%" height="40" />
      </ContentLoader>
    </div>
  );
};

export const FundraisingBarShimmer = () => {
  return (
    <div style={{ width: "100%" }}>
      <ContentLoader
        // viewBox="0 0 400 200"
        width={"100%"}
        height={"44"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="4" rx="2" ry="2" width="100%" height="16" />
        <rect x="0" y="24" rx="2" ry="2" width="100%" height="18" />
      </ContentLoader>
    </div>
  );
};

export const DonorListShimmer = () => {
  return (
    <div style={{ width: "100%" }}>
      <ContentLoader
        // viewBox="0 0 400 200"
        width={"100%"}
        height={"470"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="4" rx="2" ry="2" width="60%" height="32" />
        <rect x="0" y="60" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="100" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="140" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="180" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="220" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="260" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="300" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="340" rx="2" ry="2" width="100%" height="28" />
        <rect x="0" y="380" rx="2" ry="2" width="100%" height="28" />
      </ContentLoader>
    </div>
  );
};

export default CustomShimmer;
